import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogComponent } from './blog/blog.component';
import { ProjectsComponent } from './projects/projects.component';
import { FallingTextComponent } from './blog/falling-text/falling-text.component';
import { LandingPageComponent } from './landing-page/landing-page.component';


const routes: Routes = [

  // {path: 'post/:id', component: PostComponent},
  {path: 'blog', component: BlogComponent},
  {path: "projects", component: ProjectsComponent},
  {path: "falling-text", component: FallingTextComponent},
  {path: '', component: LandingPageComponent,  pathMatch: 'full'},
  {path: '**', redirectTo: '/blog',  pathMatch: 'full'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
