<div class="elevator">

    <!-- <div class="square"> -->
    <!-- <p class="pitch"><b>Welcome</b> to my personal website! As a software developer with expertise in <b>Angular2,
            Typescript, Java, and Python.</b> I have experience building websites in the banking industry, as well as
        personal ML/AI projects. I am passionate about <b>learning new skills</b> and <b>solving complex problems</b>,
        and I am constantly seeking out new challenges to help me grow as a developer. Whether it's building
        <b>full-stack solutions</b> from scratch or working on a team to tackle a specific challenge, I am always up for
        the task. As you browse my website, I hope you'll get a sense of my skills, experience, and <b>passion for
            software development</b>. Whether you're an employer, recruiter, or simply a friend, I look forward to
        connecting with you and exploring new opportunities together.
    </p> -->
    <!-- <p class="pitch"> -->
        <div class="intro">
            <h2 class="role"><b>{{cur_message}}</b><span class="cursor">|</span></h2>
            <br>
            <p class="based">Based in Dallas</p> 
        </div>
        
    <!-- </p> -->
    <!-- <ul>
        <li>Full Stack Developer</li>
        <li>Aspiring Indie Game Developer</li>
        <li>Private Pilot in Training</li>
        <li>Hobbiest Woodworker</li>
        <li>Musician (For My Dog)</li>
        <li>Rocky Mountain Regular</li>
        <li>Perfect Picleball Pardner</li>
        <li>Snowboarding Fiend</li>
        <li>Prolematic Plant Parent</li>
         
    </ul> -->
    
    <blog-typewritter></blog-typewritter>
    <!-- </div> -->

</div>
<!-- <div class="blog-landing-container">
    <div class="blog-vert-bars">
        <div id="vert1" class="vert-bar"></div>
        <div id="vert2" class="vert-bar"></div>
        <div id="vert3" class="vert-bar"></div>
        <div id="vert4" class="vert-bar"></div>
    </div>



    <retro-tile #edu id="edu">
        <div class="about-me">
            <div>
                <h1>ABOUT ME</h1>
                <br>
                <p>I'm a proud Fort Worth native and a Texas A&M alumnus with a Bachelor of Science in Computer Science.
                    My passion for programming and engineering principles began during my time at Texas A&M, where I
                    took classes in ML and AI, Cyber Security, and Human-Computer Interaction. I also earned a minor in
                    cyber security, which has helped me develop a strong foundation in data security and risk
                    management.
                    <br>
                    <br>
                    My values of diligence, honesty, creativity, and independence guide my approach to both my personal
                    and professional life. I believe in working hard and taking ownership of my work, while also being
                    honest and transparent with others. I strive to be creative in my problem-solving and to maintain a
                    strong sense of independence in my work.
                    <br>
                    <br>
                    My professional goals are centered around leadership, collaboration, and innovation. As a software
                    developer, I'm passionate about building robust, user-friendly solutions that solve real-world
                    problems. But beyond that, I want to take on leadership roles that allow me to guide and mentor
                    others. I believe that the best way to learn is by teaching, and I'm eager to share my knowledge and
                    experience with those around me.
                    <br>
                    <br>
                    Collaboration is also important to me. I've worked on many projects where teamwork and communication
                    were essential to success. I believe that everyone has unique talents and perspectives, and by
                    working together, we can create something truly special. I'm always looking for opportunities to
                    collaborate with others, whether it's on a team project or through mentorship and knowledge sharing.
                    <br>
                    <br>
                    Finally, I'm committed to innovation. The tech industry is constantly evolving, and I believe that
                    it's important to stay up-to-date with the latest trends and technologies. But more than that, I
                    want to be a part of the next wave of innovation. I'm excited to explore new frontiers in fields
                    like AI, machine learning, and data analytics. I want to be a part of the team that creates the next
                    groundbreaking software or platform that revolutionizes the way we live and work.
                    <br>
                    <br>
                    In my free time, I enjoy a variety of hobbies that help me stay creative, active, and engaged
                    outside of work. One of my passions is playing guitar. I've been playing for several years and find
                    it to be a great way to unwind and express myself. Whether it's strumming along to my favorite songs
                    or improvising new riffs, I love the sense of creativity and freedom that playing guitar gives me.
                    <br>
                    <br>
                    Another hobby of mine is hiking. I enjoy exploring the great outdoors and challenging myself
                    physically. Hiking is a great way for me to disconnect from technology and immerse myself in nature.
                    Whether it's a local trail or a longer backpacking trip, I always feel recharged and inspired after
                    a good hike.
                    <br>
                    <br>
                    I'm an avid aviation enthusiast and working on my private pilot's license. Flying general aviation
                    planes is one of the most exhilarating experiences I've had. The feeling of taking off and soaring
                    above the clouds is truly indescribable. It requires focus, discipline, and a deep appreciation for
                    the principles of physics and engineering.
                    <br>
                    <br>
                    I'm also interested in DIY projects. Whether it's building a new piece of furniture or
                    repairing something around the house, I enjoy the satisfaction of working with my hands and seeing
                    tangible results. DIY projects also allow me to learn new skills and techniques that I can apply to
                    other areas of my life.
                    <br>
                    <br>
                    Thank you for taking the time to visit my site. If you'd like to connect, please don't hesitate to
                    reach out to me at [INSERT CONTACT INFORMATION HERE].
                </p>
            </div>
        </div>
    </retro-tile>

</div> -->