import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'blog-tile',
  templateUrl: './blog-tile.component.html',
  styleUrls: ['./blog-tile.component.scss']
})
export class BlogTileComponent implements OnInit {

  constructor() { }

  isPressed: boolean = false;


  ngOnInit(): void {
  }

  handlePress(){
    this.isPressed = true;
  }
  handleLeave(){
    this.isPressed = false;
  }

}
