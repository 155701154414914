import { Component } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-landing-page',
  standalone: false,
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent {

  cur_message: string = '';

  private messageSubject = new Subject<string>();
  public message$ = this.messageSubject.asObservable();


  ngOnInit(): void {
    // this.contentfulService.getPostsFromCont().then((res)=>{this.posts=res;});
    // this.contentfulService.setPosts(this.posts);
    // this.contentfulService.savePosts();
    

    this.message$.subscribe((typedMessage:string)=>{
      this.cur_message = typedMessage
    })

    const messages = [
      "Full Stack Developer",
      "Aspiring Indie Game Developer",
      "Private Pilot in Training",
      "Rocky Mountain Regular"
    ];
    this.typeMessage(messages, 150, 2000);
    
    // this.about.nativeElement.ad
  }

  typeMessage(messages: string[], speed: number = 100, delay: number = 2000): void {
    let currentMessageIndex = 0;

    const typeNext = (message: string) => {
      let i = 0;
      const typeInterval = setInterval(() => {
        if (i < message.length) {
          this.messageSubject.next(message.substring(0, i + 1));
          i++;
        } else {
          clearInterval(typeInterval);
          setTimeout(() => backspaceMessage(message), delay);
        }
      }, speed);
    };

    const backspaceMessage = (message: string) => {
      let i = message.length;
      const backspaceInterval = setInterval(() => {
        if (i > 0) {
          this.messageSubject.next(message.substring(0, i - 1));
          i--;
        } else {
          clearInterval(backspaceInterval);
          currentMessageIndex = (currentMessageIndex + 1) % messages.length;
          setTimeout(() => typeNext(messages[currentMessageIndex]), 500);
        }
      }, speed*0.5);
    };

    typeNext(messages[currentMessageIndex]);
  }

}
