import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'blog-navbar',
  templateUrl: './blog-navbar.component.html',
  styleUrls: ['./blog-navbar.component.scss']
})
export class BlogNavbarComponent implements OnInit {

  expanded: boolean = true;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  shrink(): void {
    this.expanded = false
  }

  goToPort(): void {
    console.log("GO TO PORT")
    this.router.navigate(['../products'])
  }
}
