<div class="tabbed">
    <div class="left">
        <ul>
            <li *ngFor="let tab of exp; index as i" (click)="curTab=i" >
                <div class="under">
                    <h3>{{tab.name}}</h3>
                </div>
                <div [ngClass]="{'over': true, 'selected': i===curTab}">
                    <h3>{{tab.name}}</h3>
                </div>
                <!-- <div [ngClass]="{'tab-accent': true, 'selected': i===curTab}"></div> -->
            </li>
        </ul>
    </div>
    <div class="right">
        <div class="time">
            <i>{{exp[curTab].start}}-{{exp[curTab].end}}</i>
        </div>
        <br>
        <div class="info">
            {{exp[curTab].info}}
        </div>
        <br>
        <div class="tools">
            <h4><u>Development Tools</u></h4>
            
            <ul>
                <li *ngFor="let tool of exp[curTab].tools">{{tool}}</li>
            </ul>
        </div>
    </div>
</div>
