<div class="falling-wrapper">
    <div class="static-wrapper">
        <p class="left-text">Known For : </p>
    </div>
    <div class="falling-wrapper">
        <div [@listAnimation]="currentItem">
            <div *ngIf="currentItem">
                {{currentItem}}
            </div>
    </div>
    
</div>
