<div class="container">
    <!-- <img src="assets/mountain_selfie.jpg" alt=""> -->
    <retro-tile>
        <h2>About Me</h2>
        <br>
        <p>
            I've always been a curious person, taking apart anything I could get my hands on. It all started with the Nintendo 64 when I was no older than 6. As a child, I was hooked by the entertainment value of the games, but I very quickly wanted to know how they were made. I hopped on the family computer, found out that code was the key to unlocking the power behind these screens, and that games were just a small part of what was possible. I dived deep into robotics all four years of high school, and in my senior year, I took AP Computer Science, where my passion for programming really started to take root.
        </p>
        <br>
        <p>
            I went to Texas A&M for a bachelor's degree in computer science. I loved every second of my time at A&M and the lessons I learned have been invaluable. The hardest part for me was deciding what I wanted to specialize in as I found many of the paths interesting in their own rights. Some of the topics that interested me the most were Machine Learning, Operating Systems, Computer Graphics, and UX. In the end I went with a minor in Cyber Security, but I still love to learn about the other branches of computer science when I can.
        </p>
        <br>
        <p>
            I'm still the same curious person I was as a kid, and have only continued collecting interests and hobbies. I love traveling to nature, making music, working on my private pilots license, designing and building wooden furniture for myself, 3d modeling and printing for both functional and fun projects, and getting out to the golf course when I can.
        </p>
        <br>
        <p>
            It's possible I am a victim of the Dunning-Kruger effect, but I'm also certain I could land a boeing 747 no questions asked.
        </p>
        
    </retro-tile>

</div>

